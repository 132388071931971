<template>
    <div style="width: 100%;" v-loading="loading">
        <div style="width: 96%; margin-left:2%; height:240px; border: 1px solid #ddd; margin-top: 20px;">
            <p style="border-left: 4px solid blue; line-height: 20px; margin: 20px; padding-left: 10px;">提现总览</p>
            <div>
                <table style="width: 100%; margin-top: -10px; line-height: 38px;">
                    <el-row :gutter="12" style="margin-left: 10px;">
                        <el-col :span="10">
                            <el-card shadow="always">
                                <el-row>
                                    <el-col :span="12">
                                        <div style="font-size: 20px;font-weight: bold;height: 120px;line-height: 50px; ">
                                            总余额比索(MXN)
                                            <div style="font-size: 50px;font-weight: bold;color: red;">{{total.value1}}</div>
                                        </div>
                                    </el-col>
                                </el-row>
                            </el-card>
                        </el-col>
                    </el-row>
                </table>
            </div>
        </div>
        <div style="width: 96%; margin-left:2%; height:350px; border: 1px solid #ddd; margin-top: 20px;">
            <p style="border-left: 4px solid blue; line-height: 20px; margin: 20px; padding-left: 10px;">提现信息</p>
            <div>
                <table style="width: 100%; margin-top: -10px; line-height: 38px;">
                    <el-row :gutter="12" style="margin-left: 10px;">
                        <el-col v-if="total.value2 != null" :span="6">
                            <el-card shadow="hover">
                                <div slot="header" class="clearfix">
                                    <span >{{total.value2}}提现</span>
                                    <el-button v-if="total.value5> 0" style="float: right; padding: 10px 2px" type="text" @click="openadd(1)">提现</el-button>
                                </div>
                                <table style="text-align: left;">
                                    <tr>
                                        <th style="width: 220px;">{{total.value2}}</th>
                                        <th>金额</th>
                                    </tr>
                                    <tr>
                                        <th>商户余额: </th>
                                        <th>{{total.value3}}</th>
                                    </tr>
                                    <tr>
                                        <th>不可用余额: </th>
                                        <th>{{total.value4}}</th>
                                    </tr>
                                    <tr>
                                        <th>可用余额: </th>
                                        <th>{{total.value5}}</th>
                                    </tr>
                                </table>
                            </el-card>
                        </el-col>
                        <el-col v-if="total.value6 != null" :span="6">
                            <el-card shadow="hover">
                                <div slot="header" class="clearfix">
                                    <span>{{total.value6}}提现</span>
                                    <el-button v-if="total.value9 > 0" style="float: right; padding: 10px 2px" type="text" @click="openadd(2)">提现</el-button>
                                </div>
                                <table style="text-align: left;">
                                    <tr>
                                        <th style="width: 220px;">{{total.value6}}</th>
                                        <th>金额</th>
                                    </tr>
                                    <tr>
                                        <th>商户余额: </th>
                                        <th>{{total.value7}}</th>
                                    </tr>
                                    <tr>
                                        <th>不可用余额: </th>
                                        <th>{{total.value8}}</th>
                                    </tr>
                                    <tr>
                                        <th>可用余额: </th>
                                        <th>{{total.value9}}</th>
                                    </tr>
                                </table>
                            </el-card>
                        </el-col>

                        <el-col v-if="total.value10 != null" :span="6">
                            <el-card shadow="hover">
                                <div slot="header" class="clearfix">
                                    <span>{{total.value10}}提现</span>
                                    <el-button v-if="total.value11 > 0" style="float: right; padding: 10px 2px" type="text" @click="openadd(3)">提现</el-button>
                                </div>
                                <table style="text-align: left;">
                                    <tr>
                                        <th style="width: 220px;">{{total.value10}}</th>
                                        <th>金额</th>
                                    </tr>
                                    <tr>
                                        <th>商户余额: </th>
                                        <th>{{total.value11}}</th>
                                    </tr>
                                    <tr>
                                        <th>不可用余额: </th>
                                        <th>{{total.value12}}</th>
                                    </tr>
                                    <tr>
                                        <th>可用余额: </th>
                                        <th>{{total.value13}}</th>
                                    </tr>
                                </table>
                            </el-card>
                        </el-col>

                        <el-col v-if="total.value14 != null" :span="6">
                            <el-card shadow="hover">
                                <div slot="header" class="clearfix">
                                    <span>{{total.value14}}提现</span>
                                    <el-button v-if="total.value17 > 0" style="float: right; padding: 10px 2px" type="text" @click="openadd(4)">提现</el-button>
                                </div>
                                <table style="text-align: left;">
                                    <tr>
                                        <th style="width: 220px;">{{total.value14}}</th>
                                        <th>金额</th>
                                    </tr>
                                    <tr>
                                        <th>商户余额: </th>
                                        <th>{{total.value15}}</th>
                                    </tr>
                                    <tr>
                                        <th>不可用余额: </th>
                                        <th>{{total.value16}}</th>
                                    </tr>
                                    <tr>
                                        <th>可用余额: </th>
                                        <th>{{total.value17}}</th>
                                    </tr>
                                </table>
                            </el-card>
                        </el-col>

                        <el-col v-if="total.value18 != null" :span="6">
                            <el-card shadow="hover">
                                <div slot="header" class="clearfix">
                                    <span>{{total.value18}}提现</span>
                                    <el-button v-if="total.value21 > 0" style="float: right; padding: 10px 2px" type="text" @click="openadd(5)">提现</el-button>
                                </div>
                                <table style="text-align: left;">
                                    <tr>
                                        <th style="width: 220px;">{{total.value18}}</th>
                                        <th>金额</th>
                                    </tr>
                                    <tr>
                                        <th>商户余额: </th>
                                        <th>{{total.value19}}</th>
                                    </tr>
                                    <tr>
                                        <th>不可用余额: </th>
                                        <th>{{total.value20}}</th>
                                    </tr>
                                    <tr>
                                        <th>可用余额: </th>
                                        <th>{{total.value21}}</th>
                                    </tr>
                                </table>
                            </el-card>
                        </el-col>

                        <el-col v-if="total.value22 != null" :span="6">
                            <el-card shadow="hover">
                                <div slot="header" class="clearfix">
                                    <span>{{total.value22}}提现</span>
                                    <el-button v-if="total.value25 > 0" style="float: right; padding: 10px 2px" type="text" @click="openadd(6)">提现</el-button>
                                </div>
                                <table style="text-align: left;">
                                    <tr>
                                        <th style="width: 220px;">{{total.value22}}</th>
                                        <th>金额</th>
                                    </tr>
                                    <tr>
                                        <th>商户余额: </th>
                                        <th>{{total.value23}}</th>
                                    </tr>
                                    <tr>
                                        <th>不可用余额: </th>
                                        <th>{{total.value24}}</th>
                                    </tr>
                                    <tr>
                                        <th>可用余额: </th>
                                        <th>{{total.value25}}</th>
                                    </tr>
                                </table>
                            </el-card>
                        </el-col>
                    </el-row>
                </table>
            </div>
        </div>
        <pop-up :title="(this.cl == 1 ? total.value2 : 
                        this.cl == 2 ? total.value6 : 
                        this.cl == 3 ? total.value10 : 
                        this.cl == 4 ? total.value14 : 
                        this.cl == 5 ? total.value18 : total.value22) + '提现'" :isshow="showadd" :haveconfirm="true" @close="getclose" @confirm="getconfirm">
            <div slot="box" style="width: 700px;">
                <el-form ref="form" :inline="true" :model="form" :rules="rules" size="small">
                    <el-form-item label="提现金额:">
                        <el-input-number v-model="form.moeny" :min="1"/>
                    </el-form-item> 
                    <el-form-item label="提现方式:">
                        <el-select v-model="form.type" placeholder="请选择">
                            <el-option label="线上提现" value="1"></el-option>
                            <el-option label="线下提现" value="2"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="支付密码:">
                        <el-input type="password" v-model="form.paypass" />
                    </el-form-item> 
                    <el-form-item v-if="form.type == 1" label="选择银行账户">
                        <el-select size="small" v-model="form.bankId" filterable :placeholder="$t('qxz')"  style="width:300px;">
                            <el-option :label="$t('qxz')" :value="null"></el-option>
                            <el-option v-for="item in banklist"
                                :key="item.id"
                                :label="item.accountNo"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="兑换方式：">
                        <el-radio-group v-model="form.cupones">
                            <el-radio :label="1">比索</el-radio>
                            <el-radio :label="0">其他</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="提现目的:">
                        <el-input type="textarea" v-model="form.comentarios"></el-input>
                    </el-form-item> 
                </el-form>
            </div>
        </pop-up>
    </div>
</template>
<script>
import apiurl from "@/api/public"
import _api from "@/api/index"
export default {
    data(){
        return{
            loading:false,
            total:{
                value1: 0,
                value2: 0,
                value3: 0,
                value4: 0,
                value5: 0,
                value6: 0,
                value7: 0,
                value8: 0,
                value9: 0,

                value10: 0,
                value11: 0,
                value12: 0,
                value13: 0,

                value14: 0,
                value15: 0,
                value16: 0,
                value17: 0,

                value18: 0,
                value19: 0,
                value20: 0,
                value21: 0,

                value22: 0,
                value23: 0,
                value24: 0,
                value25: 0,
            },
            form: {
                id: null,
                moeny: null,
                type: null,
                cupones: null,
                comentarios: null,
                bankId: null,
                iden: null,
                status: null,
                paypass: null,
                iden:1
            },
            showadd: false,
            cl: 1,
            banklist:[]
        }
    },
    created(){
        this.getTotal();
        this.getBanklist()
    },
    methods:{
        openadd(row) {
            this.cl = row
            this.showadd = true
            this.form = {
                id: null,
                moeny: null,
                type: null,
                cupones: 1,
                comentarios: null,
                bankId: null,
                iden: null,
                status: null,
                paypass: null,
                iden:1,
                channel:row,
            }
        },
        getclose() {
            this.$refs.form.resetFields();
            this.showadd = false
        },
        getBanklist() {
            _api.get(apiurl.bank, {
            }).then(res => {
                this.banklist = res.data
            })
        },
        getconfirm() {
            if (this.form.channel == 1) {
                if (this.form.moeny > this.total.value5) {
                    this.$message.error("提现金额不能大于余额:" + this.total.value5)
                    return
                }
            } else if (this.form.channel == 2) {
                if (this.form.moeny > this.total.value9) {
                    this.$message.error("提现金额不能大于余额:" + this.total.value9)
                    return
                }
            } else if (this.form.channel == 3) {
                if (this.form.moeny > this.total.value13) {
                    this.$message.error("提现金额不能大于余额:" + this.total.value13)
                    return
                }
            } else if (this.form.channel == 4) {
                if (this.form.moeny > this.total.value17) {
                    this.$message.error("提现金额不能大于余额:" + this.total.value17)
                    return
                }
            } else if (this.form.channel == 5) {
                if (this.form.moeny > this.total.value19) {
                    this.$message.error("提现金额不能大于余额:" + this.total.value19)
                    return
                }
            } else if (this.form.channel == 6) {
                if (this.form.moeny > this.total.value23) {
                    this.$message.error("提现金额不能大于余额:" + this.total.value23)
                    return
                }
            }

            if (this.form.paypass == null) {
                this.$message.error("支付密码不能为空")
                return
            }

            if (this.form.type == null) {
                this.$message.error("提现方式不能为空")
                return
            } else {
                if (this.form.type == 1) {
                    if (this.form.bankId == null) {
                        this.$message.error("请选择银行账户")
                        return
                    }
                }
            }
            
            _api.post(apiurl.mencionar, this.form).then(res => {
                this.$notify.success({
                    title: this.$t('success'),
                    message: this.$t('addSuccess')
                })
                this.showadd = false
                this.form = {
                    id: null,
                    moeny: null,
                    type: null,
                    cupones: null,
                    comentarios: null,
                    bankId: null,
                    iden: null,
                    paypass: null,
                    status: null,
                }
                this.getTotal();
            }).catch(err => {
                this.$notify.error({
                    title: this.$t('error'),
                    message: err.data.message
                })
            })
        },
        getTotal(){
            _api.get(apiurl.withdraw,{
            }).then(res=>{
                this.total.value1=res.data.value1
                this.total.value2=res.data.value2
                this.total.value3=res.data.value3
                this.total.value4=res.data.value4
                this.total.value5=res.data.value5
                this.total.value6=res.data.value6
                this.total.value7=res.data.value7
                this.total.value8=res.data.value8
                this.total.value9=res.data.value9

                this.total.value10=res.data.value10
                this.total.value11=res.data.value11
                this.total.value12=res.data.value12
                this.total.value13=res.data.value13

                this.total.value14=res.data.value14
                this.total.value15=res.data.value15
                this.total.value16=res.data.value16
                this.total.value17=res.data.value17

                this.total.value18=res.data.value18
                this.total.value19=res.data.value19
                this.total.value20=res.data.value20
                this.total.value21=res.data.value21

                this.total.value22=res.data.value22
                this.total.value23=res.data.value23
                this.total.value24=res.data.value24
                this.total.value25=res.data.value25
            })
        }
    }
}
</script>
<style scoped>
    .ta {
        margin-top: 10px; 
        margin-bottom: 10px;                   
        line-height: 38px;
        width: 800px;
        text-align: left;
        width: 750px;
    }
    .div1 {
        width: 150px;
        height: 50px;
        line-height: 50px;
        background-color: aqua;
        border: 1px solid  black;
        text-align: center;
        font-size: 20px;
        border-radius: 5px;
        margin-left: 100px;
    }
</style>